import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`Users should be able to resize text and use custom text spacing without text being clipped, truncated, or obscured. In addition, interactive controls should not
break.`}</p>
    <h2>{`Why?`}</h2>
    <p>{`People with low-vision may rely on the browser's zoom functionality or update the font-size of a page to read content. Additionally, people with low-vision
and people with dyslexia may increase text spacing with custom CSS to improve readability.`}</p>
    <p>{`When sites are built without adequate considerations around responsiveness (for example: setting fixed width and height, using non-relative font sizing), sites may become completely unreadable
and unusable for some users.`}</p>
    <h2>{`How to test`}</h2>
    <h3>{`Text resizing`}</h3>
    <p>{`Most modern browsers support full-page zoom, while some also support text-only zoom (for example: Firefox). While WCAG does not specify how text should be resized, it is best practice for your
page to be readable with full-page zoom and text-only zoom. Aside from using the browser zoom, some users may also resize text through browser font-size setting.`}</p>
    <p>{`Test your page by using browser zoom with magnification of up to 200%. Confirm that the text content does not overflow, overlap, or become truncated, and that interactive controls still work.`}</p>
    <p>{`Learn more about resize testing techniques at `}<a parentName="p" {...{
        "href": "https://www.a11yproject.com/posts/resize-text/"
      }}>{`TheA11yProject: Resize text`}</a>{`.`}</p>
    <h3>{`Text spacing`}</h3>
    <p>{`Modify text spacing according to the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/text-spacing.html"
      }}>{`WCAG text spacing testing guidelines`}</a>{`. You can do this
by changing the CSS using the browser inspector or by using a tool like `}<a parentName="p" {...{
        "href": "https://cdpn.io/stevef/debug/YLMqbo"
      }}>{`Steve Faulkner: text spacing bookmarklet`}</a>{`.`}</p>
    <p>{`Confirm that text is contained within the bounds of its container without overlapping or being cutoff.`}</p>
    <h2>{`Guidelines`}</h2>
    <h3>{`For designers`}</h3>
    <p>{`When creating a design, ensure that font sizes and element sizes are annotated with proper resizing techniques before handing it off to an engineer. There are a variety of techniques that
can be employed to ensure responsive text and space resizing. This includes avoiding setting fixed height on a container to allow text content to expand, and using relative units such as `}<inlineCode parentName="p">{`em`}</inlineCode>{` and `}<inlineCode parentName="p">{`rem`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`px`}</inlineCode>{`.`}</p>
    <p>{`Learn more about the techniques at: `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html#techniques"
      }}>{`WCAG Resize text techniques`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/text-spacing.html#techniques"
      }}>{`WCAG Text spacing techniques`}</a>{`.`}</p>
    <h3>{`For engineers`}</h3>
    <p>{`As you're developing a feature, make sure to test the page using the resize techniques described in `}<a parentName="p" {...{
        "href": "#how-to-test"
      }}>{`How to test`}</a>{`. Ensure that `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html#techniques"
      }}>{`WCAG Resize text techniques`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/text-spacing.html#techniques"
      }}>{`WCAG Text spacing techniques`}</a>{` are employed.`}</p>
    <h2>{`Examples`}</h2>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Caption mdxType="Caption">Do ensure that the text does not overflow when text spacing is customized</Caption>
    <img width="600" alt="Screenshot of a GitHub issue description with adjusted text spacing. The issue description is contained within the bounds of the container even with adjusted text spacing " src="https://user-images.githubusercontent.com/16447748/157532517-38ae80a0-f5f8-47ec-b9fc-7d5ba5d27bd2.png" />
  </Do>
  <Do mdxType="Do">
    <Caption mdxType="Caption">
      Do use the browser zoom functionality to verify that the page is readable and functional, and make adjustments if
      necessary
    </Caption>
    <img width="800" alt="Screenshot of a GitHub markdown toolbar that becomes unusable with overlapping controls at browser magnification of 200%" src="https://user-images.githubusercontent.com/16447748/157534278-daa67dbd-ecfb-4964-ab45-13d6037355a0.png" />
  </Do>
  <Dont mdxType="Dont">
    <Caption mdxType="Caption">
      Don't use a fixed height on text content containers. In the image below, the fixed height of the container causes
      text to overflow out of bounds.
    </Caption>
    <img width="600" alt="Screenshot of a GitHub issue description where the container has a fixed height, causing the authored long description to overflow out of bounds" src="https://user-images.githubusercontent.com/16447748/157533237-a18c1cfb-d962-4349-8cbd-a390174b4ebc.png" />
  </Dont>
    </DoDontContainer>
    <h2>{`Additional resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.digitala11y.com/understanding-sc-1-4-4-resize-text/"
        }}>{`DigitalA11y: Understanding SC 1.4.4 Resize text`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dequeuniversity.com/resources/wcag2.1/1.4.12-text-spacing"
        }}>{`DequeUniversity: 1.4.12 Text Spacing (AA)`}</a></li>
    </ul>
    <h3>{`Related WCAG guidelines and success criteria`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html"
        }}>{`1.4.4 Resize text`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/text-spacing.html"
        }}>{`1.4.12 Text spacing`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      